import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createSlots as _createSlots, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { Search, Edit, Delete } from '@element-plus/icons-vue';
import { ref } from 'vue';
import { packages, deletePackages } from '@/api/packages';
import { options } from './options';
import { ElMessage, ElMessageBox } from 'element-plus';
import Dialog from '@/views/config/subscription/components/dialog.vue';
import { isNull } from '@/utils/filters';
import i18n from '@/i18n';
export default {
  __name: 'index',
  setup(__props) {
    const dialogVisible = ref(false);
    const dialogTitle = ref('');
    const dialogTableValue = ref({});
    const deleteForm = ref({
      id: ''
    });
    const queryForm = ref({
      name: ''
    });
    const tableData = ref([]);
    const initPackagesList = async () => {
      console.log(queryForm.value.name);
      const res = await packages(queryForm.value);
      tableData.value = res.list;
    };
    initPackagesList();
    const handleDialogVisible = row => {
      if (isNull(row)) {
        dialogTitle.value = '添加机器人套餐';
        dialogTableValue.value = {};
      } else {
        dialogTitle.value = '编辑机器人套餐';
        dialogTableValue.value = JSON.parse(JSON.stringify(row));
      }
      dialogVisible.value = true;
    };
    const handleDelete = row => {
      ElMessageBox.confirm(i18n.global.t('dialog.delete'), '警告', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        deleteForm.value.id = row.id;
        await deletePackages(deleteForm.value);
        ElMessage({
          type: 'success',
          message: '删除成功'
        });
        await initPackagesList();
      });
    };
    return (_ctx, _cache) => {
      const _component_el_input = _resolveComponent("el-input");
      const _component_el_col = _resolveComponent("el-col");
      const _component_el_button = _resolveComponent("el-button");
      const _component_el_row = _resolveComponent("el-row");
      const _component_el_table_column = _resolveComponent("el-table-column");
      const _component_el_table = _resolveComponent("el-table");
      const _component_el_card = _resolveComponent("el-card");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_el_card, null, {
        default: _withCtx(() => [_createVNode(_component_el_row, {
          gutter: 20,
          class: "header"
        }, {
          default: _withCtx(() => [_createVNode(_component_el_col, {
            span: 7
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              placeholder: _ctx.$t('table.placeholder_package'),
              clearable: "",
              modelValue: queryForm.value.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => queryForm.value.name = $event)
            }, null, 8, ["placeholder", "modelValue"])]),
            _: 1
          }), _createVNode(_component_el_button, {
            type: "primary",
            icon: _unref(Search),
            onClick: initPackagesList
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('table.search')), 1)]),
            _: 1
          }, 8, ["icon"]), _createVNode(_component_el_button, {
            type: "primary",
            onClick: _cache[1] || (_cache[1] = $event => handleDialogVisible(null))
          }, {
            default: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx.$t('table.add_package')), 1)]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_table, {
          data: tableData.value,
          stripe: "",
          style: {
            "width": "100%"
          }
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(options), (item, index) => {
            return _openBlock(), _createBlock(_component_el_table_column, {
              width: item.width,
              prop: item.prop,
              label: item.label,
              key: index
            }, _createSlots({
              _: 2
            }, [item.prop === 'action' ? {
              name: "default",
              fn: _withCtx(({
                row
              }) => [_createVNode(_component_el_button, {
                type: "primary",
                size: "small",
                icon: _unref(Edit),
                onClick: $event => handleDialogVisible(row)
              }, null, 8, ["icon", "onClick"]), _createVNode(_component_el_button, {
                type: "danger",
                size: "small",
                icon: _unref(Delete),
                onClick: $event => handleDelete(row)
              }, null, 8, ["icon", "onClick"])]),
              key: "0"
            } : undefined]), 1032, ["width", "prop", "label"]);
          }), 128)), _createTextVNode("> ")]),
          _: 1
        }, 8, ["data"])]),
        _: 1
      }), dialogVisible.value ? (_openBlock(), _createBlock(Dialog, {
        key: 0,
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => dialogVisible.value = $event),
        dialogTitle: dialogTitle.value,
        onInitPackagesList: initPackagesList,
        dialogTableValue: dialogTableValue.value
      }, null, 8, ["modelValue", "dialogTitle", "dialogTableValue"])) : _createCommentVNode("", true)], 64);
    };
  }
};