import request from './request'

export const packages = (params) => {
  return request({
    url: 'subscription/packages',
    params
  })
}

export const creatPackages = (params) => {
  return request({
    url: 'subscription/addPackage',
    method: 'POST',
    params
  })
}

export const updatePackages = (params) => {
  return request({
    url: 'subscription/updatePackage',
    method: 'POST',
    params
  })
}
export const deletePackages = (params) => {
  return request({
    url: 'subscription/deletePackage',
    method: 'POST',
    params
  })
}
