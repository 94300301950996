export const options = [
  {
    label: 'Id',
    prop: 'id'
  },
  {
    label: '套餐名称',
    prop: 'name'
  },
  {
    label: '描述',
    prop: 'description'
  },
  {
    label: '价格',
    prop: 'price'
  },
  {
    label: '持续天数',
    prop: 'duration'
  },
  {
    label: '操作',
    prop: 'action',
    width: 160
  }
]
